<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap16Interfaces"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 16: Interfaces" image-name="interfaces.jpg" image-alt="Interfaces"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>What is a type interface?</p></li>
<li><p>How to define an interface.</p></li>
<li><p>What “implement an interface” means.</p></li>
<li><p>The advantages of interfaces</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Interface</p></li>
<li><p>Concrete implementation</p></li>
<li><p>To implement an interface</p></li>
<li><p>The method set of an interface.</p></li>
</ul>
<div id="introduction" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> Introduction <a href="#introduction"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Interfaces seem difficult to understand when you just start programming. Often new programmers do not fully understand the potential of interfaces. This section aims to explain what an interface is, why it is interesting, and how to create interfaces.</p>
<div id="a-basic-definition-of-an-interface" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> A basic definition of an interface <a href="#a-basic-definition-of-an-interface"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>An interface is a <strong>contract</strong> that defines a set of behaviors.</p></li>
<li><p>Interfaces are <strong>a pure object of design</strong> they just define a set of behaviors (ie. Methods) <strong>without giving any implementation</strong> of those behaviors.</p></li>
<li><p>An interface <strong>is a kind of type</strong> that defines a set of methods without implementing them</p></li>
</ul>
<p>“To Implement” = “To write the code of the method” Here is an example interface type (from the standard package <span v-highlightjs><code class="go" v-pre style="display: inline">io</code></span>):</p>
<pre v-highlightjs><code class="go" v-pre >type Reader interface {
    Read(p []byte) (n int, err error)
}</code></pre>
<p>Here we have an interface type named <span v-highlightjs><code class="go" v-pre style="display: inline">Reader</code></span>. It specifies one method named <span v-highlightjs><code class="go" v-pre style="display: inline">Read</code></span>. The method has no body, no implementation. The only thing specified is the method name and its signature (parameters and results).</p>
<div id="zero-value-of-an-interface-type" class="anchor"></div>
<h4 data-number="4.0.0.1"><span class="header-section-number">4.0.0.1</span> Zero value of an interface type <a href="#zero-value-of-an-interface-type"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>The zero value of the interface type is <span v-highlightjs><code class="go" v-pre style="display: inline">nil</code></span>. Example :</p>
<pre v-highlightjs><code class="go" v-pre >var r io.Reader
log.Println(r)
// 2021/02/02 20:27:52 &lt;nil&gt;</code></pre>
<div id="basic-example" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> Basic Example <a href="#basic-example"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<pre v-highlightjs><code class="go" v-pre >type Human struct {
    Firstname string
    Lastname string
    Age int
    Country string
}

type DomesticAnimal interface {
    ReceiveAffection(from Human)
    GiveAffection(to Human)
}</code></pre>
<ul>
<li><p>First, we declare a type named <span v-highlightjs><code class="go" v-pre style="display: inline">Human</code></span></p></li>
<li><p>The we declare a new type interface named <span v-highlightjs><code class="go" v-pre style="display: inline">DomesticAnimal</code></span>.</p></li>
<li><p>This type interface has a method set composed of two methods : <span v-highlightjs><code class="go" v-pre style="display: inline">ReceiveAffection</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">GiveAffection</code></span><strong>.</strong></p></li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">DomesticAnimal</code></span> is a contract.</p>
<ul>
<li>It signals to the developer that to be a <span v-highlightjs><code class="go" v-pre style="display: inline">DomesticAnimal</code></span> we need to have at least two behaviors: <span v-highlightjs><code class="go" v-pre style="display: inline">ReceiveAffection</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">GiveAffection</code></span></li>
</ul>
<p>Let’s create two types :</p>
<pre v-highlightjs><code class="go" v-pre >type Cat struct {
    Name string
}

type Dog struct {
    Name string
}</code></pre>
<p>We have two new types. To make them <strong>respect the contract</strong> of our interface <span v-highlightjs><code class="go" v-pre style="display: inline">DomesticAnimal</code></span>, we have to define for each type the methods dictated by the interface.</p>
<p>Let’s begin with the <strong>Cat</strong> type :</p>
<pre v-highlightjs><code class="go" v-pre >func (c Cat) ReceiveAffection(from Human) {
    fmt.Printf(&quot;The cat named %s has received affection from Human named %s\n&quot;, c.Name, from.Firstname)
}

func (c Cat) GiveAffection(to Human) {
    fmt.Printf(&quot;The cat named %s has given affection to Human named %s\n&quot;, c.Name, to.Firstname)
}</code></pre>
<p>Now the type <span v-highlightjs><code class="go" v-pre style="display: inline">Cat</code></span> implements the <span v-highlightjs><code class="go" v-pre style="display: inline">DomesticAnimal</code></span> <strong>interface</strong>. We do the same now for the <span v-highlightjs><code class="go" v-pre style="display: inline">Dog</code></span> type :</p>
<pre v-highlightjs><code class="go" v-pre >func (d Dog) ReceiveAffection(from Human) {
    fmt.Printf(&quot;The dog named %s has received affection from Human named %s\n&quot;, d.Name, from.Firstname)
}

func (d Dog) GiveAffection(to Human) {
    fmt.Printf(&quot;The dog named %s has given affection to Human named %s\n&quot;, d.Name, to.Firstname)
}</code></pre>
<p>Our <span v-highlightjs><code class="go" v-pre style="display: inline">Dog</code></span> type implements now correctly the <span v-highlightjs><code class="go" v-pre style="display: inline">DomesticAnimal</code></span> <strong>interface.</strong> Now we can create a function that takes an interface has a parameter :</p>
<pre v-highlightjs><code class="go" v-pre >func Pet(animal DomesticAnimal, human Human) {
    animal.GiveAffection(human)
    animal.ReceiveAffection(human)
}</code></pre>
<p>This function can take any type that implements the type interface <span v-highlightjs><code class="go" v-pre style="display: inline">DomesticAnimal</code></span> as parameter. Therefore we do not have to create specific functions for cats, dogs, snakes, rats.… This function is generic and can be executed for different types :</p>
<pre v-highlightjs><code class="go" v-pre >func Pet(animal DomesticAnimal, human Human) {
    animal.GiveAffection(human)
    animal.ReceiveAffection(human)
}</code></pre>
<p>The <span v-highlightjs><code class="go" v-pre style="display: inline">Pet</code></span> function takes a <span v-highlightjs><code class="go" v-pre style="display: inline">DomesticAnimal</code></span> type interface as a first parameter and an <span v-highlightjs><code class="go" v-pre style="display: inline">Human</code></span> as a second parameter.</p>
<p>Inside the function, we call the two functions of the interface.</p>
<p>Let’s use this function :</p>
<pre v-highlightjs><code class="go" v-pre >// interfaces/first-example/main.go 
//...

func main() {

    // Create the Human
    var john Human
    john.Firstname = &quot;John&quot;


    // Create a Cat
    var c Cat
    c.Name = &quot;Maru&quot;

    // then a dog
    var d Dog
    d.Name = &quot;Medor&quot;

    Pet(c, john)
    Pet(d,john)
}</code></pre>
<ul>
<li><p>The types <span v-highlightjs><code class="go" v-pre style="display: inline">Dog</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">Cat</code></span> <strong>implement</strong> the methods of the interface <span v-highlightjs><code class="go" v-pre style="display: inline">DomesticAnimal</code></span></p></li>
<li><p>=&gt; any variable of type <span v-highlightjs><code class="go" v-pre style="display: inline">Dog</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">Cat</code></span> <strong>can be seen as</strong> a <span v-highlightjs><code class="go" v-pre style="display: inline">DomesticAnimal</code></span></p></li>
</ul>
<div id="the-compiler-is-watching-you" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> The compiler is watching you! <a href="#the-compiler-is-watching-you"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Respecting the interface contract for a type <strong>T</strong> means implementing all methods of the interface. Let’s try to fool the compiler to see what happens :</p>
<pre v-highlightjs><code class="go" v-pre >// ...
// let&#39;s create a concrete type Snake
type Snake struct {
    Name string
}
// we do not implement the methods ReceiveAffection and GiveAffection intentionally
//...


func main(){

    var snake Snake
    snake.Name = &quot;Joe&quot;

    Pet(snake, john)
}</code></pre>
<ul>
<li><p>We create a new type <span v-highlightjs><code class="go" v-pre style="display: inline">Snake</code></span></p></li>
<li><p>This type does not implement any methods of the <span v-highlightjs><code class="go" v-pre style="display: inline">DomesticAnimal</code></span> animal</p></li>
<li><p>In the main function, we create a new variable of type <span v-highlightjs><code class="go" v-pre style="display: inline">Snake</code></span></p></li>
<li><p>We then call the <span v-highlightjs><code class="go" v-pre style="display: inline">Pet</code></span> function with this variable as first parameter.</p></li>
</ul>
<p>It does not compile :</p>
<pre v-highlightjs><code class="go" v-pre >./main.go:70:5: cannot use snake (type Snake) as type DomesticAnimal in argument to Pet:
    Snake does not implement DomesticAnimal (missing GiveAffection method)</code></pre>
<p>The compiler stops at the first method in alphabetical order that is not implemented.</p>
<div id="example-databasesqldriver.driver" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> Example : <span v-highlightjs><code class="go" v-pre style="display: inline">database/sql/driver.Driver</code></span> <a href="#example-databasesqldriver.driver"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Let’s take a look at the <span v-highlightjs><code class="go" v-pre style="display: inline">Driver</code></span> interface (from the package <span v-highlightjs><code class="go" v-pre style="display: inline">database/sql/driver</code></span>)</p>
<pre v-highlightjs><code class="go" v-pre >type Driver interface {
    Open(name string) (Conn, error)
}</code></pre>
<ul>
<li><p>Different sorts of SQL databases exist, so there are several implementations of the method <span v-highlightjs><code class="go" v-pre style="display: inline">Open</code></span><strong>.</strong></p></li>
<li><p>Why? Because you will not use the same code to initiate a connection to a MySQL database and an Oracle database.</p></li>
<li><p>By building an interface, you define a contract that several implementations can use.</p></li>
</ul>
<div id="interface-embedding" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> Interface embedding <a href="#interface-embedding"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>You can <strong>embed</strong> interfaces into other interfaces. Let’s take an example :</p>
<pre v-highlightjs><code class="go" v-pre >// the Stringer type interface from the standard library
type Stringer interface {
    String() string
}
// A homemade interface
type DomesticAnimal interface {
    ReceiveAffection(from Human)
    GiveAffection(to Human)
    // embed the interface Stringer into the DomesticAnimal interface
    Stringer
}</code></pre>
<p>In the previous listing, we embed the interface <span v-highlightjs><code class="go" v-pre style="display: inline">Stringer</code></span> into the interface <span v-highlightjs><code class="go" v-pre style="display: inline">DomesticAnimal</code></span>.</p>
<p>As a consequence, the other types that already implement <span v-highlightjs><code class="go" v-pre style="display: inline">DomesticAnimal</code></span> have to implement the methods of the <span v-highlightjs><code class="go" v-pre style="display: inline">DomesticAnimal</code></span> interface.</p>
<ul>
<li><p>With interface embedding, you can add functionality to your interfaces without duplication.</p></li>
<li><p>It also comes at a cost; if you embed an interface from another module, your code will be coupled with it</p>
<ul>
<li><p>A change in the other module interface will force you to rewrite your code.</p></li>
<li><p>Note that this danger is moderated if the dependant module follow the semantic versioning scheme<a href="#fn1" class="footnote-ref" id="fnref1" role="doc-noteref"><sup>1</sup></a></p></li>
<li><p>You can use interfaces from the standard library without fear</p></li>
</ul></li>
</ul>
<div id="some-useful-and-famous-interfaces-from-the-standard-library" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> Some useful (and famous) interfaces from the standard library <a href="#some-useful-and-famous-interfaces-from-the-standard-library"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="error-interface" class="anchor"></div>
<h2 data-number="9.1"><span class="header-section-number">9.1</span> Error interface <a href="#error-interface"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >type error interface {
    Error() string
}</code></pre>
<p>This interface type is intensively used. Functions or methods that can fail return an <span v-highlightjs><code class="go" v-pre style="display: inline">error</code></span> type interface :</p>
<pre v-highlightjs><code class="go" v-pre >func (c *Communicator) SendEmailAsynchronously(email *Email) error {
    //...
}</code></pre>
<p>To create an error, we usually call : <span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Errorf()</code></span> which returns a result of type <span v-highlightjs><code class="go" v-pre style="display: inline">error</code></span></p>
<ul>
<li>or <span v-highlightjs><code class="go" v-pre style="display: inline">errors.New()</code></span></li>
</ul>
<p>Of course, it’s possible to create your type that implements the error interface.</p>
<div id="fmt.stringer-interface" class="anchor"></div>
<h2 data-number="9.2"><span class="header-section-number">9.2</span> fmt.Stringer interface <a href="#fmt.stringer-interface"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >type Stringer interface {
    String() string
}</code></pre>
<p>With the <span v-highlightjs><code class="go" v-pre style="display: inline">Stringer</code></span> interface, you can define how a type will be printed as a <span v-highlightjs><code class="go" v-pre style="display: inline">string</code></span>, when a printing method is called (<span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Errorf(),fmt.Println, fmt.Printf, fmt.Sprintf</code></span>...)</p>
<p>Here is an example implementation</p>
<pre v-highlightjs><code class="go" v-pre >type Human struct {
    Firstname string
    Lastname string
    Age int
    Country string
}

func (h Human) String() string {
    return fmt.Sprintf(&quot;human named %s %s of age %d living in %s&quot;,h.Firstname,h.Lastname,h.Age,h.Country)
}</code></pre>
<p>Human implements now <span v-highlightjs><code class="go" v-pre style="display: inline">Stringer</code></span>:</p>
<pre v-highlightjs><code class="go" v-pre >// interfaces/stringer/main.go 
package main 

func main() {
    var john Human
    john.Firstname = &quot;John&quot;
    john.Lastname = &quot;Doe&quot;
    john.Country = &quot;USA&quot;
    john.Age = 45

    fmt.Println(john)
}</code></pre>
<p>Outputs :</p>
<pre v-highlightjs><code class="go" v-pre >human named John Doe of age 45 living in the USA</code></pre>
<div id="sort.interface" class="anchor"></div>
<h2 data-number="9.3"><span class="header-section-number">9.3</span> sort.Interface <a href="#sort.interface"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>By implementing this interface on a type, you can sort the elements of a type (usually, the underlying type is a slice)</p>
<pre v-highlightjs><code class="go" v-pre >type Interface interface {
    Len() int
    Less(i, j int) bool
    Swap(i, j int)
}</code></pre>
<p>Here is an example usage (source :sort/example_interface_test.go) :</p>
<pre v-highlightjs><code class="go" v-pre >type Person struct {
    Age int
}
// ByAge implements sort.Interface for []Person based on
// the Age field.
type ByAge []Person

func (a ByAge) Len() int           { return len(a) }
func (a ByAge) Swap(i, j int)      { a[i], a[j] = a[j], a[i] }
func (a ByAge) Less(i, j int) bool { return a[i].Age &lt; a[j].Age }</code></pre>
<ul>
<li><p>The type <span v-highlightjs><code class="go" v-pre style="display: inline">ByAge</code></span> implements the <span v-highlightjs><code class="go" v-pre style="display: inline">sort.Interface</code></span>.</p>
<ul>
<li>The underlying type is a slice of <span v-highlightjs><code class="go" v-pre style="display: inline">Person</code></span></li>
</ul></li>
<li><p>The interface is composed of three methods :</p>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">Len() int</code></span> : should return the number of elements inside the collection</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">Less(i, j int) bool</code></span> : returns <span v-highlightjs><code class="go" v-pre style="display: inline">true</code></span> if the element at index <span v-highlightjs><code class="go" v-pre style="display: inline">i</code></span> should be sorted before the element at index <span v-highlightjs><code class="go" v-pre style="display: inline">j</code></span></p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">Swap(i, j int)</code></span> : should swap the elements at index <span v-highlightjs><code class="go" v-pre style="display: inline">i</code></span> &amp; <span v-highlightjs><code class="go" v-pre style="display: inline">j</code></span>; in other words, we should put the element at index <span v-highlightjs><code class="go" v-pre style="display: inline">j</code></span> at index <span v-highlightjs><code class="go" v-pre style="display: inline">i</code></span>, and the element at index <span v-highlightjs><code class="go" v-pre style="display: inline">i</code></span> should be put at index <span v-highlightjs><code class="go" v-pre style="display: inline">j</code></span>.</p></li>
</ul></li>
</ul>
<p>We can then sort a variable of type <span v-highlightjs><code class="go" v-pre style="display: inline">ByAge</code></span> with the <span v-highlightjs><code class="go" v-pre style="display: inline">sort.Sort</code></span> function</p>
<pre v-highlightjs><code class="go" v-pre >// interfaces/sort/main.go 

func main() {
    people := []Person{
        {&quot;Bob&quot;, 31},
        {&quot;John&quot;, 42},
        {&quot;Michael&quot;, 17},
        {&quot;Jenny&quot;, 26},
    }

    sort.Sort(ByAge(people))
}</code></pre>
<div id="implicit-implementation" class="anchor"></div>
<h1 data-number="10"><span class="header-section-number">10</span> Implicit implementation <a href="#implicit-implementation"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Interfaces are <strong>implicitly</strong> implemented. When you declare a type you do not have to specify which interfaces it implements.</p>
<div id="php-and-java" class="anchor"></div>
<h1 data-number="11"><span class="header-section-number">11</span> PHP and JAVA <a href="#php-and-java"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In other languages, you have to specify the interface implementations.</p>
<p>Here is an example in Java :</p>
<pre v-highlightjs><code class="go" v-pre >// JAVA
public class Cat implements DomesticAnimal{
    public void receiveAffection(){
        //...
    }
    public void giveAffection(){
        //..
    }
}</code></pre>
<p>Here is another example in PHP</p>
<pre v-highlightjs><code class="go" v-pre >//PHP
&lt;?php

class Cat implements DomesticAnimal {
    public function receiveAffection():void {
        // ...
    }
    public function giveAffection():void {
        // ...
    }
}
?&gt;</code></pre>
<p>In those two languages, we have <strong>classes</strong>. You can see that you have to add the term “<strong>implements</strong>” when you declare a class that implements an interface.</p>
<p>You might ask how the Go runtime handles those implicit interface implementations. We will try to draw an explanation of the mechanism of interface values.</p>
<div id="the-empty-interface" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="12"><span class="header-section-number">12</span> The empty interface <a href="#the-empty-interface"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Go’s empty interface is the simplest and smaller interface you can write. Its method set is composed of exactly 0 methods.</p>
<pre v-highlightjs><code class="go" v-pre >interface {}</code></pre>
<p>That being said, every type implement the empty interface. You might ask why such a boring interface is interesting. By definition, an empty interface value can hold values of any type. It’s useful if you want to build a method that accepts any type.</p>
<p>Let’s take some examples from the standard library.</p>
<ul>
<li>In the <span v-highlightjs><code class="go" v-pre style="display: inline">log</code></span> package you have a <span v-highlightjs><code class="go" v-pre style="display: inline">Fatal</code></span> method that takes as input variables of any type :</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >func (l *Logger) Fatal(v ...interface{}) { }</code></pre>
<ul>
<li>In the <span v-highlightjs><code class="go" v-pre style="display: inline">fmt</code></span> package, we also have many methods that take as input an empty interface. For instance the <span v-highlightjs><code class="go" v-pre style="display: inline">Printf</code></span> function :</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >func Printf(format string, a ...interface{}) (n int, err error) { }</code></pre>
<div id="type-switch" class="anchor"></div>
<h2 data-number="12.1"><span class="header-section-number">12.1</span> Type switch <a href="#type-switch"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>A function that accepts an empty interface as a parameter generally needs to know its input parameter’s effective type.</p>
<p>To do so, the function can use a “type switch”, this is a switch case that will compare a type instead of a value.</p>
<p>Here is an example taken from the standard library (file <span v-highlightjs><code class="go" v-pre style="display: inline">runtime/error.go</code></span>, package <span v-highlightjs><code class="go" v-pre style="display: inline">runtime</code></span>) :</p>
<pre v-highlightjs><code class="go" v-pre >// printany prints an argument passed to panic.
// If panic is called with a value that has a String or Error method,
// it has already been converted into a string by preprintpanics.
func printany(i interface{}) {
    switch v := i.(type) {
    case nil:
        print(&quot;nil&quot;)
    case bool:
        print(v)
    case int:
        print(v)
    case int8:
        print(v)
    case int16:
        print(v)
    case int32:
        print(v)
    case int64:
        print(v)
    case uint:
        print(v)
    case uint8:
        print(v)
    case uint16:
        print(v)
    case uint32:
        print(v)
    case uint64:
        print(v)
    case uintptr:
        print(v)
    case float32:
        print(v)
    case float64:
        print(v)
    case complex64:
        print(v)
    case complex128:
        print(v)
    case string:
        print(v)
    default:
        printanycustomtype(i)
    }
}</code></pre>
<figure>
<b-img :src="require('@/assets/images/switch_cases_syntax.png')" alt="Type switch syntax"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Type switch syntax</figcaption>
</figure>
<div id="about-the-usage-of-the-empty-interface" class="anchor"></div>
<h2 data-number="12.2"><span class="header-section-number">12.2</span> About the usage of the empty interface <a href="#about-the-usage-of-the-empty-interface"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li><p>You should use the empty interface very carefully.</p></li>
<li><p>Use the empty interface when you have no other choices.</p></li>
<li><p>The empty interface <strong>gives no information</strong> to the person who will use your functions or methods, hence they will have to refer to the documentation, which can be frustrating.</p></li>
<li><p>If you accept an empty interface, your function/method will have to check the input type, making the code more complex.</p></li>
</ul>
<p>Which method do you prefer?</p>
<pre v-highlightjs><code class="go" v-pre >func (c Cart) ApplyCoupon(coupon Coupon) error  {
    //...
}

func (c Cart) ApplyCoupon2(coupon interface{}) (interface{},interface{}) {
    //...
}</code></pre>
<p>The method <span v-highlightjs><code class="go" v-pre style="display: inline">ApplyCoupon</code></span> strictly specify the type that it will accept and return. Whereas <span v-highlightjs><code class="go" v-pre style="display: inline">ApplyCoupon2</code></span> does not specify its types in input and output. As a caller, it is more difficult to use <span v-highlightjs><code class="go" v-pre style="display: inline">ApplyCoupon2</code></span> compared to <span v-highlightjs><code class="go" v-pre style="display: inline">ApplyCoupon</code></span>.</p>
<div id="application-cart-storage" class="anchor"></div>
<h1 data-number="13"><span class="header-section-number">13</span> Application: Cart storage <a href="#application-cart-storage"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="specification" class="anchor"></div>
<h2 data-number="13.1"><span class="header-section-number">13.1</span> Specification <a href="#specification"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>You build an e-commerce website; you have to store and retrieve customer carts. The three following behaviors have to be developed :</p>
<ol type="1">
<li><p>Get a cart by its ID</p></li>
<li><p>Put a cart in the database</p></li>
</ol>
<p>Propose an interface for those two behaviors.</p>
<p>Create also a type that implements those two interfaces. (Do not implement the logic in the method.)</p>
<div id="solution" class="anchor"></div>
<h2 data-number="13.2"><span class="header-section-number">13.2</span> Solution <a href="#solution"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Here is a proposed interface :</p>
<pre v-highlightjs><code class="go" v-pre >// interfaces/application/main.go 

type CartStore interface {
    GetById(ID string) (*cart.Cart, error)
    Put(cart *cart.Cart) (*cart.Cart, error)
}</code></pre>
<p>A type that implements the interface :</p>
<pre v-highlightjs><code class="go" v-pre >type CartStoreMySQL struct{}

func (c *CartStoreMySQL) GetById(ID string) (*cart.Cart, error) {
    // implement me
}

func (c *CartStoreMySQL) Put(cart *cart.Cart) (*cart.Cart, error) {
    // implement me
}</code></pre>
<p>And another type that implements the interface :</p>
<pre v-highlightjs><code class="go" v-pre >type CartStorePostgres struct{}

func (c *CartStorePostgres) GetById(ID string) (*cart.Cart, error) {
    // implement me
}

func (c *CartStorePostgres) Put(cart *cart.Cart) (*cart.Cart, error) {
    // implement me
}</code></pre>
<ul>
<li><p>You can create a specific implementation for each model of database you use</p></li>
<li><p>Adding support for a new database engine is easy ! You just have to create a new type that implements the interface.</p></li>
</ul>
<div id="why-should-you-use-interfaces" class="anchor"></div>
<h1 data-number="14"><span class="header-section-number">14</span> Why should you use interfaces? <a href="#why-should-you-use-interfaces"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="evolutivity" class="anchor"></div>
<h2 data-number="14.1"><span class="header-section-number">14.1</span> Evolutivity <a href="#evolutivity"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>When you use interfaces as input in your methods or functions, you design your program to be evolutive. Future developers (or a future you) can create new implementations without changing large portions of code.</p>
<p>Imagine that you built an application that performs database reads, insert, and updates. You can use two design approaches :</p>
<ol type="1">
<li><p>Create a type and methods that are closely linked to the database engine you are using now.</p></li>
<li><p>Create an interface that lists all the operations and a concrete implementation for your database engine.</p></li>
</ol>
<ul>
<li><p>In the first approach, you create methods that will take as parameter a specific implementation.</p></li>
<li><p>By doing so, you lock the program to one implementation</p></li>
<li><p>In the second approach, you create methods that accept an interface.</p></li>
<li><p>Changing the implementation is as easy as creating a new type that implements the interface</p></li>
</ul>
<div id="improve-teamwork" class="anchor"></div>
<h2 data-number="14.2"><span class="header-section-number">14.2</span> Improve teamwork <a href="#improve-teamwork"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Teams can also benefit from interfaces.</p>
<p>When building a functionality, it often takes more than one developer to do the job. If the job requires code written by two teams to interact, they can agree on one or several interfaces.</p>
<p>The two sets of developers can then work on their code and use the interface agreed. They can even mock the work of the other team. By doing so, the team is not blocked by the other.</p>
<div id="benefit-from-a-set-of-routines" class="anchor"></div>
<h2 data-number="14.3"><span class="header-section-number">14.3</span> Benefit from a set of routines <a href="#benefit-from-a-set-of-routines"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>When you implement interfaces on your custom type, you can use additional functionalities that do not need to be developed. Let’s take an example from the standard library: the <span v-highlightjs><code class="go" v-pre style="display: inline">sort</code></span> package. This is not a surprise; this package is used to... sort things. Here is an extract of the go source code :</p>
<pre v-highlightjs><code class="go" v-pre >// go v.1.10.1
package sort
//..

type Interface interface {
    // Len is the number of elements in the collection.
    Len() int
    // Less reports whether the element with
    // index i should sort before the element with index j.
    Less(i, j int) bool
    // Swap swaps the elements with indexes i and j.
    Swap(i, j int)
}

// Sort sorts data.
// It makes one call to data.Len to determine n, and O(n*log(n)) calls to
// data.Less and data.Swap. The sort is not guaranteed to be stable.
func Sort(data Interface) {
    n := data.Len()
    quickSort(data, 0, n, maxDepth(n))
}</code></pre>
<p>On the first line, we declare the current package : <span v-highlightjs><code class="go" v-pre style="display: inline">sort</code></span><strong>.</strong> On the next lines, the programmers declared an interface called simply <span v-highlightjs><code class="go" v-pre style="display: inline">Interface</code></span><strong>.</strong> This interface <span v-highlightjs><code class="go" v-pre style="display: inline">Interface</code></span> specifies three methods : <span v-highlightjs><code class="go" v-pre style="display: inline">Len, Less, Swap</code></span>.</p>
<p>On the next lines, the function <span v-highlightjs><code class="go" v-pre style="display: inline">Sort</code></span> is declared. It takes as parameter <span v-highlightjs><code class="go" v-pre style="display: inline">data</code></span> that is of type <span v-highlightjs><code class="go" v-pre style="display: inline">Interface</code></span>. This is a very useful function that will sort the data given.</p>
<p>How do we can use this function on one of our types? Implement the interface :</p>
<p>Imagine that you have a type User :</p>
<pre v-highlightjs><code class="go" v-pre >type User struct {
    firstname string
    lastname string
    totalTurnover float64
}</code></pre>
<p>And a type Users that is a slice of <span v-highlightjs><code class="go" v-pre style="display: inline">User</code></span> instances :</p>
<pre v-highlightjs><code class="go" v-pre >type Users []User</code></pre>
<p>Let’s create an instance of <span v-highlightjs><code class="go" v-pre style="display: inline">Users</code></span> and populate it with three variables of type <span v-highlightjs><code class="go" v-pre style="display: inline">User</code></span> <strong>:</strong></p>
<pre v-highlightjs><code class="go" v-pre >user0 := User{firstname:&quot;John&quot;, lastname:&quot;Doe&quot;, totalTurnover:1000}
user1 := User{firstname:&quot;Dany&quot;, lastname:&quot;Boyu&quot;, totalTurnover:20000}
user2 := User{firstname:&quot;Elisa&quot;, lastname:&quot;Smith Brown&quot;, totalTurnover:70}

users := make([]Users,3)
users[0] = user0
users[1] = user1
users[2] = user2</code></pre>
<p>What if we want to sort it by turnover? We can develop from scratch a sorting algorithm that matches our specifications. Or we can just implement the interface needed to use the built-in function.<span v-highlightjs><code class="go" v-pre style="display: inline">Sort</code></span> from the <span v-highlightjs><code class="go" v-pre style="display: inline">sort</code></span> package. Let’s do it :</p>
<pre v-highlightjs><code class="go" v-pre >// Compute the length of the array. Easy...
func (users Users) Len() int {
  return len(users)
}

// decide which instance is bigger than the other one
func (users Users) Less(i, j int) bool {
  return users[i].totalTurnover &lt; users[j].totalTurnover
}

// swap two elements of the array
func (users Users) Swap(i, j int) {
    users[i], users[j] = users[j], users[i]
}</code></pre>
<p>By declaring those functions, we can simply use the <strong>Sort</strong> function :</p>
<pre v-highlightjs><code class="go" v-pre >sort.Sort(users)
fmt.Println(users)
// will output :
[{Elisa Smith Brown 70} {John Doe 1000} {Dany Boyu 20000}]</code></pre>
<div id="bits-of-advice" class="anchor"></div>
<h1 data-number="15"><span class="header-section-number">15</span> Bits of Advice <a href="#bits-of-advice"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ol type="1">
<li><p>Use the interfaces provided by the Standard library</p></li>
<li><p>An interface with too many methods is hard to implement (because it requires writing many methods).</p></li>
</ol>
<div id="test-yourself" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="16"><span class="header-section-number">16</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="16.1"><span class="header-section-number">16.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>Give an example of an interface that embeds another interface.</p></li>
<li><p>True or false? Methods specified in embedded interfaces are <strong>not</strong> part on an interface method set.</p></li>
<li><p>Name two advantages of using interfaces.</p></li>
<li><p>What is the zero value of the interface type?</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="16.2"><span class="header-section-number">16.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>Give an example of an interface that embeds another interface.</p>
<pre v-highlightjs><code class="go" v-pre >type ReadWriter interface {
    Reader
    Writer
}</code></pre></li>
<li><p>True or false? Methods specified in embedded interfaces are <strong>not</strong> part on an interface method set?</p>
<ol type="1">
<li><p>False</p></li>
<li><p>The method set of an interface is composed of :</p>
<ol type="1">
<li><p>The methods directly specified into the interface</p></li>
<li><p>Methods from the embedded interface.</p></li>
</ol></li>
</ol></li>
<li><p>Name two advantages of using interfaces in your code.</p>
<ol type="1">
<li><p>Split the work between developers easily:</p>
<ol type="1">
<li><p>Define an interface type</p></li>
<li><p>One person develop an implementation of the interface</p></li>
<li><p>Another person can use the interface type in its feature</p></li>
<li><p>The two persons can work without mutual interferences.</p></li>
</ol></li>
<li><p>Evolutivity</p>
<ol type="1">
<li><p>When you create an interface, you create a contract.</p></li>
<li><p>Different implementations can fulfill this contract.</p></li>
<li><p>At the beginning of a project, there is usually one implementation</p></li>
<li><p>But with time, another implementation might be needed.</p></li>
</ol></li>
</ol></li>
<li><p>What is the zero value of the interface type?</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">nil</code></span></p></li>
</ol>
<div id="key-takeaways" class="anchor"></div>
<h1 data-number="17"><span class="header-section-number">17</span> Key takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>An interface is a <strong>contract</strong></p></li>
<li><p>It specifies methods (behaviors) without implementing them.</p>
<pre v-highlightjs><code class="go" v-pre >type Cart interface {
    GetById(ID string) (*cart.Cart, error)
    Put(cart *cart.Cart) (*cart.Cart, error)
}</code></pre></li>
<li><p>An interface is a type (like structs, arrays, maps, ...)</p></li>
<li><p>We call the methods specified in an interface the method set of the interface</p></li>
<li><p>A type can implement multiple interfaces.</p></li>
<li><p>There is no need to explicit that a type implements an interface</p>
<ul>
<li>As opposed to other languages which require to declare it (PHP, Java,...)</li>
</ul></li>
<li><p>An interface might be embedded into another interface; in that case, the embedded interface methods are added to the interface.</p></li>
<li><p>Interface types can be used like any other type</p></li>
<li><p>The zero value of the interface type is <span v-highlightjs><code class="go" v-pre style="display: inline">nil</code></span>.</p></li>
<li><p>Any type implement the empty interface <span v-highlightjs><code class="go" v-pre style="display: inline">interface{}</code></span></p></li>
<li><p>The empty interface specifies 0 methods</p></li>
<li><p>To get the concrete type of an empty interface, you can use a type switch :</p>
<pre v-highlightjs><code class="go" v-pre >switch v := i.(type) {
    case nil:
        print(&quot;nil&quot;)
    case bool:
        print(v)
    case int:
        print(v)
}</code></pre></li>
<li><p>When we can implement a behavior in various ways, we can probably create an interface.</p>
<ul>
<li>Ex: Storage (we can use a MySQL, Postgres, DynamoDB, Redis database to store the same data)</li>
</ul></li>
</ul>
<section class="footnotes" role="doc-endnotes">
<hr />
<ol>
<li id="fn1" role="doc-endnote"><p>See the Go module chapter to have more information about semantic versioning.<a href="#fnref1" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
</ol>
</section>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap16Interfaces"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap15PointerType'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Pointer type</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap17GoModules'}">
									<p><u><small>Next</small></u></p>
									<p><small>Go modules</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Interfaces - Practical Go Lessons"
const description = "An interface is a contract that defines a set of behaviors.Interfaces are a pure object of design they just define a set of behaviors (ie. Methods) without giving any implementation of those behaviors."

export default {
  name: "Chap16Interfaces",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
